html {
  font-family: 'Raleway', 'Open Sans', 'Roboto', sans-serif;
  background-color: white;
  position: relative;
  min-height: 100%;
  font-size: 16px;
  font-size-adjust: auto;
}

@media (max-width: 900px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 12px;
  }
}

/* Type will scale with document */
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bold;
  font-family: 'Roboto';
}
h5 {
  font-size: 1.25rem;
}

:root {
  --accent: black;
  --border-width: 5px;
}

.root {
  border-width: 5px;
}
body {
  background-color: white;
  display: flex;
  flex-direction: column;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  margin: auto;
  padding: 25px;
  flex: 1 0 auto;
}

div.red {
  color: red;
}
/*footer*/

.copyright {
  margin: 15px 0;
}

/*navigation bar icons*/

.navbar-icon {
  font-size: 125%;
  display: inline-block !important;
}

/*coloured borders at top and bottom of the page*/

.navbar.navbar-default {
  border-bottom: var(--border-width) solid var(--accent);
}

/* footer {
  border-top: var(--border-width) solid var(--accent);
} */

img {
  max-width: 80%;
}
