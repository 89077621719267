.App {
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 3em;
  flex: 1 0 auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2em;
  line-height: 2em;
  background-color: #f3f3f3;
}
